import React from "react"
// import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import GalleryContainer from "../containers/Gallery/Gallery";
// import Image from "../components/image"
// import Test from "../components/Test/test"

const Gallery = () => (
  <Layout>
    <SEO title="Gallery" />
    <GalleryContainer></GalleryContainer>
  </Layout>
)

export default Gallery
