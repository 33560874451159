import React, { Component } from "react"
import { StaticQuery, graphql } from "gatsby"
import css from "./Gallery.module.scss"
import HeaderNav from "../HeaderNav/HeaderNav"
import Img from "gatsby-image"
import Masonry from "react-masonry-component"

const masonryOptions = {
  transitionDuration: 0,
}

const imagesLoadedOptions = { background: ".my-bg-image-el" };

// const PRODUCT_COLOR_BLACK = 'PRODUCT_COLOR_BLACK';
const PRODUCT_COLOR = {
  Black: "Black",
  Brown: "Brown",
  Sand: "Sand",
  Gold: "Gold",
  Burgundy: "Burgundy",
}

const ORDER_TYPE = {
  PreOrder: "urlPreOrder",
  ReadyToWear: "urlReadyToWear",
  MadeToWear: "urlMadeToOrder",
}

const MATERIAL_TYPE = {
  Leather: "Leather",
  Suede: "Suede",
}

// sort types
const SORT_TYPE = {
  Default: "Default",
  Asc: "Asc",
  Desc: "Desc",
}

class Gallery extends Component {
  constructor(props) {
    super(props)

    this.productList = []
    this.productGroups = []

    this.state = {
      titleLoaded: false,
      productList: [],
      // productFilters: [],
      filters: [],
      sort: "",
    }
  }
  componentDidMount() {
    let productList = this.props.data.allContentfulProductPage.nodes
    if (productList) {
      console.log("productList");
      console.log(productList);
      // let productGroups = []

      // for (let i = 0; i < productList.length; i++) {
      //   if (productList[i] && productList[i].tags) {
      //     for (let y = 0; y < productList[i].tags.length; y++) {
      //       if (productList[i].tags[y] && productList[i].tags[y].tagGroups && productList[i].tags[y].tagGroups[0].value) {
      //         let isFoundTagGroup = false
      //         for (let t = 0; t < productGroups.length; t++) {
      //           if (productGroups[t].filterName === productList[i].tags[y].tagGroups[0].value) {
      //             isFoundTagGroup = true
      //             productGroups[t].filterValues.indexOf(productList[i].tags[y].value)
      //             productGroups[t].filterValues.push(productList[i].tags[y].value)
      //           }
      //         }
      //         if (!isFoundTagGroup) {
      //           productGroups.push({ filterName: productList[i].tags[y].tagGroups[0].value, filterValues: [productList[i].tags[y].value] })
      //         }
      //       }
      //     }
      //   }
      // }

      // sort by order of products for best results in grid formatting
      productList.sort(function(a, b) {
        return a.order - b.order
      })

      this.productList = productList;
      for (let i = 0; i < productList.length; i++) {
        productList[i].filteredProducts = productList[i].subProducts;
        for (let t = 0; t < productList[i].filteredProducts.length; t++) {
          productList[i].filteredProducts[t].stringDesc = productList[i].filteredProducts[t].color[0] + ' ' + productList[i].filteredProducts[t].materialType[0]; 
        }
        
        productList[i].mainImageSizes = productList[i].filteredProducts[0].image.image.sizes;
      }
      // this.setState({ productList: productList, productFilters: productGroups }, () => {
      this.setState({ productList: productList }, () => {
        console.log("this.state")
        console.log(this.state)
      })
    }
  }
  render() {
    console.log("this.state.productList");
    console.log(this.state.productList);
    return (
      <div className={css.container}>
        <div className={this.state.titleLoaded ? css.navHeaderContainerTransition : css.navHeaderContainer}>
          <HeaderNav titleLoaded={true} backgroundWhiteColor={true}></HeaderNav>
        </div>
        <div className={css.pageWrapper}>
          {/* <div className={css.filtersWrapper}>
            {this.state.productFilters.map((productFilterItem, productFilterIndex) => {
              return (
                <div key={productFilterIndex} className={css.filterType}>
                  <div>{productFilterItem.filterName}</div>
                </div>
              )
            })}
          </div> */}
          {/* <div className={css.productContainer}> */}
          <Masonry
            className={["my-gallery-class", css.productContainer].join(" ")} // default ''
            elementType={"ul"} // default 'div'
            options={masonryOptions} // default {}
            disableImagesLoaded={false} // default false
            updateOnEachImageLoad={false} // default false and works only if disableImagesLoaded is false
            imagesLoadedOptions={imagesLoadedOptions} // default {}
          >
            {this.state.productList.map((productItem, productItemIndex) => {
              return productItem.header ? (
                <li key={productItemIndex}>
                  {/* <a target="_blank" href={'./'}> */}
                  <div className={css.productTitleWrapper}>
                    <div className={css.productTitle}>{productItem.price} USD</div>
                    <div className={css.productTitle}>{productItem.header}</div>
                  </div>
                  <div className={css.productImageContainer}>
                    <Img sizes={productItem.mainImageSizes} className={[css.gatsbyImageWrapper, css.mainProductImage].join(" ")} />
                    <div className={css.productImageList}>
                      {productItem.filteredProducts.map(productSubItem => {
                        return productSubItem && productSubItem.image && productSubItem.image.image ? (
                          <div className={css.productSubImageContainer}>
                            {/* <a target="_blank" rel="noopener" className={'event_product_link'} href={productSubItem.url} data-action-detail={productItem.header + ' : ' + productSubItem.stringDesc} */}
                              {/* data-action-category={"product links"} data-action-category-type={productItem.header}> */}
                            <Img sizes={productSubItem.image.image.sizes} className={[css.gatsbyImageWrapper, css.productSubImage].join(' ')} />
                            <div className={css.productDescription}>
                              {productSubItem.stringDesc}
                            </div>
                            <div className={css.redirectUrlWrapper}>
                              {productSubItem.urlReadyToWear && <a target="_blank" rel="noopener" className={'event_product_link'} href={productSubItem.urlReadyToWear} data-action-detail={productItem.header + ' : ' + productSubItem.stringDesc}
                                data-action-category={"product links"} data-action-category-type={productItem.header}>
                                  Ready Order
                              </a>}
                              {productSubItem.urlPreOrder && <a target="_blank" rel="noopener" className={'event_product_link'} href={productSubItem.urlPreOrder} data-action-detail={productItem.header + ' : ' + productSubItem.stringDesc}
                                data-action-category={"product links"} data-action-category-type={productItem.header}>
                                  Pre Order
                              </a>}
                              {productSubItem.urlMadeToOrder && <a target="_blank" rel="noopener" className={'event_product_link'} href={productSubItem.urlMadeToOrder} data-action-detail={productItem.header + ' : ' + productSubItem.stringDesc}
                                data-action-category={"product links"} data-action-category-type={productItem.header}>
                                  Made to Order
                              </a>}
                            </div>
                          </div>
                        ) : (
                          <React.Fragment></React.Fragment>
                        )
                      })}
                    </div>
                  </div>
                  {/* </a> */}
                </li>
              ) : (
                <React.Fragment></React.Fragment>
              )
            })}
          </Masonry>
          {/* </div> */}
        </div>
      </div>
    )
  }
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        allContentfulProductPage {
          nodes {
            name
            header
            order
            price
            subProducts {
              name
              color
              materialType
              urlMadeToOrder
              urlPreOrder
              urlReadyToWear
              image {
                id
                image {
                  sizes(maxWidth: 1080) {
                    ...GatsbyContentfulSizes
                  }
                  file {
                    url
                  }
                }
              }
            }
            productImage {
              image {
                sizes(maxWidth: 1080) {
                  ...GatsbyContentfulSizes
                }
                file {
                  url
                }
              }
            }
          }
        }
      }
    `}
    render={data => <Gallery data={data} {...props} />}
  />
)
